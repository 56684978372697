import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'
import { exportData } from '@/utils'
import { mdiAccountOutline } from '@mdi/js'
import { inject, ref, watch } from 'vue'

export default function useGroupList() {
  const groupListTable = ref([])
  const tableColumns = [
    { text: 'Nombre', value: 'name' },
    { text: 'Curso', value: 'courseName' },
    { text: 'Referencia', value: 'reference' },
    { text: 'Modalidad', value: 'courseModality' },
    { text: 'Materias', value: 'subjects' },
    { text: 'Alumnos', value: 'students' },
    { text: 'Empresa', value: 'company' },
    { text: 'Inicio', value: 'initDate' },
    {
      text: 'Acciones',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const modalityFilter = ref(null)
  const yearFilter = ref(null)
  const yearList = ref([])
  const companyFilter = ref(null)
  const totalGroupListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [false],
  })
  const groupTotalLocal = ref([])
  const selectedRows = ref([])
  const timer = ref('')
  const date = inject('date')

  const fetchYears = async () => {
    const response = await store.dispatch('app-group/fetchYears');

    yearList.value = response.data.years
    if(!yearFilter.value) yearFilter.value = Number(date().format('YYYY'))
  }

  // fetch data
  const fetchGroups = () => {
    loading.value = true
    selectedRows.value = []

    store
      .dispatch('app-group/fetchGroups', {
        q: searchQuery.value,
        options: options.value,
        modality: modalityFilter.value,
        company: companyFilter.value,
        year: yearFilter.value,
      })
      .then(response => {
        const { filteredData, total, groupTotal } = response.data

        groupListTable.value = filteredData
        totalGroupListTable.value = total
        groupTotalLocal.value = groupTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        loading.value = false
      })
  }

  // fetch data
  const deleteGroup = id => {
    store
      .dispatch('app-group/deleteGroup', { id })
      .then(response => {
        fetchGroups()
        snackbarPlugin.showMessage({ content: 'Eliminado Correctamente', color: 'success', timeout: 10000 })
      })
      .catch(e => {
        snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
      })
  }

  const exportGroups = async () => {
    let data = groupListTable.value.map(e => ({
      Nombre: e.name,
      Curso: e.courseName,
      Modalidad: e.courseModality,
      Empresa: e.companyData ? e.companyData.name : '',
      Estudiantes: e.students.length,
      Materias: e.subjects.length,
    }))

    exportData(data, 'grupos.csv')
  }

  const getInitDate = item => {
    let earliestClassDay = null;

    item.subjects.forEach(subject => {
      subject.classDays.forEach(classDay => {
        if (!earliestClassDay || new Date(classDay.start) < new Date(earliestClassDay.start)) {
          earliestClassDay = classDay
        }
      })
    })

    return earliestClassDay ? date(earliestClassDay.start).format('DD/MM/YYYY') : ""
  }

  watch([searchQuery, options], () => {
    if (timer.value) {
      clearTimeout(timer.value)
      timer.value = null
    }
    timer.value = setTimeout(() => {
      fetchGroups()
    }, 800)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveGroupTotalIcon = total => {
    if (total === 'Total Grupos') return { icon: mdiAccountOutline, color: 'primary' }
    return { icon: mdiAccountOutline, color: 'primary' }
  }

  const resolveGroupModalityTitle = role => {
    return store.state['app-group'].modalityOptions.filter(el => el.value == role)[0].title
  }

  fetchYears()

  return {
    getInitDate,
    yearFilter,
    yearList,
    exportGroups,
    groupListTable,
    tableColumns,
    searchQuery,
    roleFilter,
    modalityFilter,
    companyFilter,
    totalGroupListTable,
    loading,
    options,
    groupTotalLocal,
    selectedRows,
    timer,
    fetchGroups,
    deleteGroup,
    resolveGroupTotalIcon,
    resolveGroupModalityTitle,
    date,
  }
}
