<template>
  <div id="group-list">
    <!-- group total card -->
    <v-row class="mb-5">
      <v-col v-for="total in groupTotalLocal" :key="total.total" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveGroupTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveGroupTotalIcon(total.title).color}--text`"
            >
              <v-icon size="25" :color="resolveGroupTotalIcon(total.title).color" class="rounded-0">
                {{ resolveGroupTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Grupos </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="2">
          <v-select
            v-model="yearFilter"
            placeholder="Año del Grupo"
            :items="yearList"
            @change="fetchGroups"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
        <!-- role filter -->
        <v-col cols="12" md="4">
          <v-select
            v-model="modalityFilter"
            placeholder="Modalidad"
            :items="$store.state['app-group'].modalityOptions"
            item-text="title"
            item-value="value"
            @change="fetchGroups"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <companies-selection v-model="companyFilter" @change="fetchGroups" class="mb-6"></companies-selection>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Buscar"
          outlined
          hide-details
          dense
          class="group-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" :to="{ name: 'apps-group-view' }" v-if="$can('create', 'group')">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Grupo</span>
          </v-btn>

          <v-btn color="secondary" outlined class="mb-4" @click="exportGroups">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        item-key="_id"
        :headers="tableColumns"
        :items="groupListTable"
        :options.sync="options"
        :footer-props="{
          'items-per-page-text': 'Grupos por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        :server-items-length="totalGroupListTable"
        loading-text="Cargando grupos..."
        :loading="loading"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'apps-group-view', params: { id: item._id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              <span class="text-capitalize">{{ item.name }}</span>
            </router-link>
          </div>
        </template>

        <!-- subjects -->
        <template #[`item.subjects`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.subjects.length }}</span>
          </div>
        </template>

        <!-- students -->
        <template #[`item.students`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.students.length }}</span>
          </div>
        </template>

        <!-- modality -->
        <template #[`item.courseModality`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ resolveGroupModalityTitle(item.courseModality) }}</span>
          </div>
        </template>

        <!-- Created -->
        <template #[`item.initDate`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ getInitDate(item) }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-group-view', params: { id: item._id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Detalle</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="deleteItem(item._id)" v-if="$can('delete', 'group')">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Eliminar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- company -->
        <template #[`item.company`]="{ item }">
          <div class="d-flex align-center">
            <span>{{ item.companyData ? `${item.companyData.fantasyName} - ${item.companyData.name}` : '' }}</span>
          </div>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay grupos para mostrar </template>
      </v-data-table>
    </v-card>

    <!-- dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title> ¿Estás seguro de que quieres eliminar este grupo? </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="closeDelete"> Cancelar </v-btn>
          <v-btn color="success" @click="deleteItemConfirm"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from 'vue'

import CompaniesSelection from '@/views/components/CompaniesSelection.vue'

// sidebar
import { avatarText } from '@core/utils/filter'
import groupStoreModule from '../groupStoreModule'

import useGroupsList from './useGroupsList'

export default {
  components: { CompaniesSelection },
  setup() {
    const appGroup = 'app-group'

    // Register module
    if (!store.hasModule(appGroup)) store.registerModule(appGroup, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appGroup)) store.unregisterModule(appGroup)
    })

    const {
      groupListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      modalityFilter,
      companyFilter,
      totalGroupListTable,
      loading,
      options,
      groupTotalLocal,
      selectedRows,
      exportGroups,
      yearFilter,
      yearList,
      fetchGroups,
      deleteGroup,
      resolveGroupTotalIcon,
      resolveGroupModalityTitle,
      getInitDate,
      date,
    } = useGroupsList()

    const dialogDelete = ref(false)
    const deleteId = ref(null)

    const deleteItem = item => {
      deleteId.value = item
      dialogDelete.value = true
    }

    const closeDelete = () => {
      dialogDelete.value = false
      deleteId.value = null
    }

    const deleteItemConfirm = () => {
      deleteGroup(deleteId.value)
      closeDelete()
    }

    return {
      getInitDate,
      yearFilter,
      yearList,
      exportGroups,
      dialogDelete,
      deleteItem,
      closeDelete,
      deleteItemConfirm,
      groupListTable,
      tableColumns,
      searchQuery,
      roleFilter,
      modalityFilter,
      companyFilter,
      totalGroupListTable,
      loading,
      options,
      groupTotalLocal,
      selectedRows,
      avatarText,
      resolveGroupTotalIcon,
      fetchGroups,
      resolveGroupModalityTitle,
      date,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/group.scss';
</style>
